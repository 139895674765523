<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    NUTRISENSE
                </div>
                <div class="head-text-secondary">
                    Nature-based Solutions for Nutrient Management in Agriculture P.C.
                    <div style="font-size: 26px;" class="mt-6">
                        a spinoff company of the Agricultural University of Athens
                    </div>
                </div>
            </div>
        </div>
        <div class="features-container mt-14">
            <div class="feature-card">
                <div class="feature-text-main">
                    <br>
                    Soilless
                </div>
                <div class="feature-text-main">
                    Nutrisense
                </div>
                <div class="feature-text-secondary">
                    <br>
                    A Decision Support System for management of fertigation and calculation of nutrient solutions in soilless culture
                </div>
                <div class="mt-10">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="subscribe"
                    >
                        SUBSCRIBE NOW
                    </v-btn>
                </div>
                <div class="mt-2">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        v-show="!isLoggedIn"
                        @click="login"
                    >
                        Login to SOILLESS NutriSense
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        v-show="isLoggedIn"
                        @click="navigate"
                    >
                        Login to SOILLESS NutriSense
                    </v-btn>
                </div>
            </div>
            <div class="feature-card">
                <div class="feature-text-main">
                    <br>
                    Soil
                </div>
                <div class="feature-text-main">
                    Nutrisense
                </div>
                <div class="feature-text-secondary">
                    <br>
                    A Decision Support System for sustainable fertilization management in soil-grown crops
                    <br><br>
                </div>
                <div class="mt-10">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="subscribe"
                    >
                        SUBSCRIBE NOW
                    </v-btn>
                </div>
                <div class="mt-2">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        v-show="!isLoggedIn"
                        @click="login"
                    >
                        Login
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        v-show="isLoggedIn"
                        @click="navigate"
                    >
                        Login to SOIL NutriSense
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="features-container mt-14">
            <div class="feature-card">
                <div class="feature-text-main">
                    <br>
                    Advisory service
                </div>
                <div class="feature-text-main">
                    packages
                    <br>
                </div>
                <div class="mt-10">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="goTo('advisoryPackages')"
                    >
                        LEARN MORE
                    </v-btn>
                </div>
            </div>
            <div class="feature-card">
                <div class="feature-text-main">
                    <br>
                    Seminars
                    <br>
                </div>
                <div class="feature-text-main">
                    and training courses
                </div>
                <div class="mt-10">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="goTo('seminars')"
                    >
                        LEARN MORE
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="pricing-container mt-14">
            <div ref="pricing" class="head">
                Pricing
            </div>
            <div class="description">
                Choose Your Plan according to your needs
            </div>
            <div class="box-container mt-16">
                <div class="pricing-card pt-16">
                    <div class="main-text">
                        Trial
                    </div>
                    <div class="secondary-text">
                        Free
                    </div>
                    <div class="pricing-list-container mt-6">
                        <v-divider></v-divider>
                        <ul class="mt-5">
                            <li>
                                30 days free access to Soil NutriSense or Soilless NutriSense
                            </li>
                        </ul>
                    </div>
                    <div class="mt-10">
                        <v-btn
                            elevation="0"
                            color="#6BB00B"
                            @click="addToCart(374)"
                        >
                            SUBSCRIBE NOW
                        </v-btn>
                    </div>
                </div>
                <div class="pricing-card active pt-16">
                    <div class="main-text mt-10">
                        Professional
                    </div>
                    <div class="secondary-text">
                        200€
                        <span>
                            PER YEAR
                        </span>
                    </div>
                    <div class="pricing-list-container mt-3">
                        <v-divider></v-divider>
                        <ul class="mt-5">
                            <li>
                                Free access to Soil NutriSense & Soilless NutriSense
                            </li>
                            <li>
                                Option to draw charts
                            </li>
                            <li>
                                Option to export the output in professional form
                            </li>
                        </ul>
                    </div>
                    <div class="mt-10">
                        <v-btn
                            elevation="0"
                            color="#6BB00B"
                            @click="addToCart(2941)"
                        >
                            SUBSCRIBE NOW
                        </v-btn>
                    </div>
                    <div class="ribbon ribbon-top-right">
                        <span>Best Seller</span>
                    </div>
                </div>
                <div class="pricing-card pt-16">
                    <div class="main-text">
                        Standard
                    </div>
                    <div class="secondary-text">
                        150€
                        <span>
                            PER YEAR
                        </span>
                    </div>
                    <div class="pricing-list-container mt-4">
                        <v-divider></v-divider>
                        <ul class="mt-5">
                            <li>
                                Free access to Soil NutriSense OR Soilless NutriSense
                            </li>
                        </ul>
                    </div>
                    <div class="mt-10">
                        <v-btn
                            elevation="0"
                            color="#6BB00B"
                            @click="addToCart(3898)"
                        >
                            SUBSCRIBE NOW
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="supported-by-container mt-16 pt-10">
            <v-row>
                <v-col>
                    <div class="text-center">
                        <v-divider class="mb-14" style="width: 50%; margin: auto;"/>
                        <h1 style="font-weight: 300">The development of the <span class="green-text">NUTRISENSE DSS</span> was/is supported by the following research projects: </h1>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card elevation="0" outlined class="programs-card">
                        <v-card-text>
                            <div class="sectionA">
                                <div class="text-center img">
                                    <img :src="require('@/assets/programs/nutrisense.png')" style="width: 70%; height: auto;">
                                </div>
                                <div class="mt-1 pt-1 ml-2 programs-card-main-text">
                                    Development of an innovative technology using ion selective electrodes and suitable software for hydroponic production with emphasis on recycling of the drainage solution in closed systems
                                </div>
                            </div>
                            <v-divider class="mt-5 mb-4" style="width: 70%; margin: auto;"/>
                            <div class="programs-card-main-secondary-text ml-2">
                                The project <span class="green-text">NUTRISENSE</span> was funded by the Hellenic Foundation for Research and Innovation (HFRI).
                                <br><br>
                                Project No: <strong>HFRI-FM17-3196 – NUTRISENSE</strong>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card elevation="0" outlined class="programs-card">
                        <v-card-text>
                            <div class="sectionA">
                                <div class="text-center img">
                                    <img :src="require('@/assets/programs/vegadapt.jpg')" style="width: 40%; height: auto;">
                                </div>
                                <div class="mt-1 pt-1 ml-2 programs-card-main-text">
                                    Adapting Mediterranean vegetable crops to climate change-induced multiple stress
                                </div>
                            </div>
                            <v-divider class="mt-5 mb-4" style="width: 70%; margin: auto;"/>
                            <div class="programs-card-main-secondary-text ml-2">
                                The project <span class="green-text">VEG-ADAPT</span> was funded by the Greek General Secretariat for Research and Innovation (GSRI) within the framework of the European Project PRIMA
                                <br><br>
                                Project No: <strong>Prima2018-11</strong>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card elevation="0" outlined class="programs-card">
                        <v-card-text>
                            <div class="sectionA">
                                <div class="text-center img">
                                    <img :src="require('@/assets/programs/econutri.png')" style="width: 50%; height: auto;">
                                </div>
                                <div class="mt-1 pt-1 ml-2 programs-card-main-text">
                                    Innovative concepts and technologies for ECOlogically sustainable NUTRIent management in agriculture aiming to prevent, mitigate and eliminate pollution in soils, water and air
                                </div>
                            </div>
                            <v-divider class="mt-5 mb-4" style="width: 70%; margin: auto;"/>
                            <div class="programs-card-main-secondary-text ml-2">
                                The project <span class="green-text">ECONUTRI </span> is funded by the European Union´s Horizon Europe research and innovation programme
                                <br><br>
                                Project No: <strong>101081858</strong>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="d-none">
                <v-col>
                    <div class="mt-16 pt-4">
                        <v-card elevation="2" outlined style="min-height: 380px;">
                            <v-card-text>
                                <img :src="require('@/assets/programs/nutrisense.png')">
                                <br>
                                <div class="mt-14 pt-6">
                                    Development of an innovative technology using ion selective electrodes and suitable software for hydroponic production with emphasis on recycling of the drainage solution in closed systems
                                </div>
                                <br><br>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="2" outlined class="mt-4" style="min-height: 380px;">
                            <v-card-text>
                                The project <span class="green-text">NUTRISENSE</span> was funded by the Hellenic Foundation for Research and Innovation (<strong>HFRI</strong>)
                                <div class="mt-5">
                                    Project No: HFRI-FM17-3196 – NUTRISENSE
                                </div>
                                <div class="mt-8">
                                    <img :src="require('@/assets/programs/hfri.png')">
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                <v-col>
                    <div class="mt-16 pt-4">
                        <v-card elevation="2" outlined style="min-height: 380px;">
                            <v-card-text>
                                <img :src="require('@/assets/programs/vegadapt.jpg')">
                                <br>
                                Adapting Mediterranean vegetable crops to climate change-induced multiple stress
                                <br><br>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="2" outlined class="mt-4" style="min-height: 380px;">
                            <v-card-text>
                                The project <span class="green-text">vegadapt</span> was funded by the Greek General Secretariat for Research and Innovation (GSRI) within the framework of the European Project PRIMA
                                <div class="mt-5">
                                    Project No: Prima2018-11
                                </div>
                                <div class="mt-4">
                                    <img :src="require('@/assets/programs/prima.png')">
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                <v-col>
                    <div class="mt-16 pt-4">
                        <v-card elevation="2" outlined style="min-height: 380px;">
                            <v-card-text>
                                <img :src="require('@/assets/programs/econutri.png')">
                                <br>
                                <div class="mt-14 pt-4">
                                    Innovative concepts and technologies for ECOlogically sustainable NUTRIent management in agriculture aiming to prevent, mitigate and eliminate pollution in soils, water and air
                                </div>
                                <br><br>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="2" outlined class="mt-4" style="min-height: 380px;">
                            <v-card-text>
                                The project <span class="green-text">ECONUTRI </span> is funded by the European Union´s Horizon Europe research and innovation programme
                                <div class="mt-5">
                                    Project No: 101081858
                                </div>
                                <div class="mt-4">
                                    <img :src="require('@/assets/programs/horizon.png')">
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div>
            <form action="/crm/?product=dss-full-subscription-for-1-year-trial" method="POST" ref="package1">
                <input type="hidden" name="quantity" value="1">
                <input type="hidden" name="add-to-cart" value="374">
            </form>
        </div>
    </div>
</template>

<script>
    import user from '@/services/user'
    import shop from '@/services/shop'
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
            isLoggedIn: false
        }),
        watch: {
            $route() {
                if (window.location.hash === '#pricing') {
                    setTimeout(() => {
                        this.$refs.pricing.scrollIntoView({ behavior: 'smooth' })
                    }, 300)
                }
            }
        },
        mounted() {
            user.isLoggedIn().then(() => {
                this.isLoggedIn = true
            })
        },
        computed: {
        },
        created() {
            if (window.location.hash === '#pricing') {
                setTimeout(() => {
                    this.$refs.pricing.scrollIntoView({ behavior: 'smooth' })
                }, 300)
            }
            user.isLoggedIn().then(() => {
                this.loggedIn = true
            })
        },
        methods: {
            login() {
                document.getElementById('login_btn').click()
            },
            addToCart(productId) {
                shop.addToCart(productId).then(() => {
                    // window.location = '/crm/?page_id=57'
                    window.location = '/checkout'
                })
            },
            navigate() {
                window.location = 'http://calculator.nutrisense.online/'
            },
            subscribe() {
                this.$refs.pricing.scrollIntoView({ behavior: 'smooth' })
            },
            goTo(routeName) {
                this.$router.push({ name: routeName, replace: true })
            }
        }
    }
</script>
<style scoped>
/* .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    max-width: 100%;
}

.logo-wrapper {
    width: 40%;
}

.welcome-message {
    font-weight: bold;
    font-size: calc(1vw + 2vh);
} */
.green-text {
    color: #006600;
}
.head-container{
    background-image: url("~@/assets/home_head.png");
    height: 660px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
    background-size: cover;
    background-position: center;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 36px;
    font-weight: bold;
    color: #000;
}

.features-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 8rem;
}

.feature-card {
    background: #F5F5F5;
    border-radius: 25px;
    width: 520px;
    padding: 30px;
    text-align: center;
}
.feature-text-main {
    font-weight: 700;
    font-size: 36px;
    line-height: 1;
}

.feature-text-secondary {
    color: #646464;
    font-size: 20px;
    line-height: 2rem;
    max-width: 80%;
    line-height: 2.3rem;
    margin: 20px auto;
}

.v-btn {
    color: #fff!important;
    font-weight: 400;
    font-size: 16px!important;
    border-radius: 10px!important;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
    height: 45px!important;
}

.pricing-container {
    text-align: center;
}

.pricing-container .head {
    color: #226100;
    font-size: 48px;
}

.pricing-container .description {
    color: #226100;
    font-size: 20px;
}

.pricing-card {
    border-radius: 25px;
    box-shadow: 0px 4px 30px 0px #00000026;
    width: 360px;
    padding-bottom: 3rem;
    color: #226100;
    position: relative;
    overflow: hidden;
}

.pricing-card.active {
    margin-top: -40px;
    background: #226100;
    color: #fff;
}

.pricing-card .main-text {
    font-size: 24px;
}

.pricing-card .secondary-text {
    font-size: 48px;
}

.pricing-card .secondary-text span {
    font-size: 14px;
    display: block;
    margin-top: -10px;
}
.box-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1rem;
}

.ribbon {
  width: 210px;
  height: 210px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #fff;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #6BB00B;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: 10px;
  right: 10px;
  transform: rotate(45deg);
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.pricing-list-container {
    max-width: 90%;
    margin: auto;
    text-align: left;
}
.pricing-card.active hr {
    border-color: rgb(255 255 255 / 61%);
}
ul {
    min-height: 120px;
}
.supported-by-container {
    width: 70%;
    margin: auto;
}
.inner-container {
    width: 936px;
    margin: auto;
    position: relative;
}
.text-box {
    background: #CED94C;
    border-right: 12px solid #000000;
    color: #226100;
    padding: 50px 15px 30px 10%;
    padding: 20px;
    width: 70%;
    position: absolute;
    right: 10%;
    top: 45%;
    min-height: 160px;
    font-size: 18px;
}
.text-box .col {
    position: relative;
    min-height: 140px;
}
.text-box img {
    /* position: absolute; */
    bottom: 0px;
    width: 30%;
    height: auto;
}

.programs-card-main-secondary-text, .programs-card-main-text {
    font-size: 1.1em;
}
.programs-card {
    min-height: 100%;
    position: relative;
}
.programs-card-main-text {
    min-height: 55px;
}
.programs-card-main-secondary-text {
    position: abs2olute;
    bottom: 10px;
}
.sectionA {
    min-height: 200px;
}
.sectionA .img {
    min-height: 100px;
}
</style>
