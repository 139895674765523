import api from '@/utils/api'

export default {
    addToCart(productId) {
        // let bodyFormData = new FormData()
        // bodyFormData.append('quantity', 1)
        // bodyFormData.append('add-to-cart', productId)
        // return api.post(`?product=${productId}`, bodyFormData)
        // return api.post(`?add-to-cart=${productId}`)
        return api.get(`private/fApi/addCart.php?product=${productId}`)
    }
}
